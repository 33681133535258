@import '~bulma/sass/utilities/initial-variables';
@import '~bulma/sass/utilities/derived-variables';
@import './overrides/utilities/initial-variables';
@import './overrides/utilities/derived-vars';
//body
$body-line-height: 1.8;

// Spacing

$block-spacing: 2rem;

// Miscellaneous

$easing: ease-out;
$radius-small: 2px;
$radius: 4px;
$radius-large: 10px;
$radius-medium: 20px;
$radius-xlarge: 33px;
$radius-rounded: 290486px;
$speed: 86ms;

// buttons
$button-border-width: 2px;

$button-padding-vertical: calc(0.475em - #{$button-border-width});
$button-padding-horizontal: 1.5rem;

$button-border-color: $grey;

// box

$box-radius: $radius-large;
$box-shadow: 0 15px 35px rgba($blue-ter, 0.2);
$box-shadow-red: 0px 0px 15px 0px rgba(212, 0, 0, 0.5);
$box-padding: 2.125rem;

// card

$card-shadow: $box-shadow;
$card-header-padding: 0.75rem;
$card-content-padding: 2rem;
$card-footer-padding: 0.75rem;

$card-footer-padding: 2rem;
$card-footer-background-color: $primary;
$card-footer-border-top: 0;

$card-radius: $radius-large; // custom

// section
$section-padding: 3rem 1.5rem;
$section-padding-medium: 5rem 1.5rem;

// images

$dimensions: 16 24 32 48 64 96 128 300;

// modal

// $modal-card-head-radius: $radius-xlarge;
// $modal-card-foot-radius: $radius-xlarge;
// $modal-card-head-background-color: $white;
// $modal-card-head-border-bottom: 0;
// $modal-card-foot-border-top: 0;
// $modal-card-body-padding: 0;
// $modal-card-head-padding: 3.125rem 1rem 1rem 1rem;

// navbar

$navbar-height: 4.0625rem;
$navbar-item-color: $black;

// tags

$tag-radius: $radius-large;

// controls

$control-radius: $radius-large;
$control-radius-small: $radius-small;

$control-border-width: 2px;

$control-height: 3.125rem;
$control-line-height: 1.5;

$control-padding-vertical: calc(
  0.468em - #{$control-border-width}
); //15% $control-height
$control-padding-horizontal: calc(
  0.868em - #{$control-border-width}
); //27.8% $control-height

// inputs shared

$input-color: $black;
// $input-background-color: $white !default
$input-border-color: $grey;
$input-height: $control-height;
$input-shadow: none;

$input-hover-color: $input-color;
$input-hover-border-color: $input-border-color;

$input-focus-color: $input-color;
$input-focus-border-color: $grey-dark;
$input-focus-box-shadow-size: 0px 0px;
$input-focus-box-shadow-color: $input-focus-border-color;

$input-arrow: $black;
$input-radius: $radius-large;
$input-icon-color: $grey-dark;
$input-icon-active-color: $input-icon-color;

// label

$label-weight: $weight-semibold;
$label-color: $grey-dark;
$help-size: $size-small;

// footer
$footer-background-color: $white;

// title
$title-color: inherit;

// hr
//$hr-margin: 1.3125rem 0;

// placeholder
$input-placeholder-color: #808080;

$breadcrumb-item-separator-color: #000;
