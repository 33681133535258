@import 'styles/all_vars';

.m-head__nav.m-head__nav--close {
  position: fixed;
  right: 15px;
  height: 32px;
  width: 32px;

  .img-slider__close {
    position: relative;
  }
}

#page-pop-product {
  margin-top: 0;

  .pop-page-content {
    padding-top: 0;
  }

  .headroom-wrapper {
    min-height: $navbar-height;
    max-height: $navbar-height;
  }

  .headroom {
    position: relative;
    display: block !important;

    @include tablet() {
      display: none !important;
    }
  }

  .item-address {
    margin-top: 2rem;

    .title {
      margin-bottom: 1rem !important;

      &:hover {
        cursor: pointer;
      }

      @include mobile() {
        font-size: 0.925rem;
      }
    }

    //.frame {
    //  pointer-events: none;
    //
    //  @include tablet() {
    //    pointer-events: auto;
    //  }
    //}
  }
}

.product-content__footer {
  display: none;
  @include tablet() {
    display: block;
  }
}

.link-to-map {
  font-size: small;
  margin-top: -10px;
  margin-bottom: 10px;
  display: block;
  text-decoration: none !important;
  color: $red !important;

  @include tablet() {
    display: none;
  }
}

.pop-page.search-popup {
  z-index: 11;
}

@mixin navFix {
  margin-top: $navbar-height;
  position: fixed;
  overflow: hidden;
  z-index: 10;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @include tablet() {
    position: relative;
    overflow: inherit;
  }
}

.pop-page {
  height: 100%;
  @include navFix();

  @include tablet() {
    &.checkout {
      margin-top: 0;
    }
  }

  &.hide {
    display: none;
  }
}

.pop-page__backdrop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
  opacity: 0.9;
}

.pop-page__backdrop--white {
  background: #fff;
  opacity: 1;
}

.pop-page--force {
  @include navFix();
  @include tablet() {
    position: fixed;
  }

  .pop-page-outer {
    position: absolute;
    overflow: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.pop-page--modal {
  position: fixed;
  overflow: auto !important;
  margin-top: $navbar-height;
  padding-bottom: 6rem;
  z-index: 50;

  .modal-background {
    position: fixed;
  }

  .pop-page-outer {
    min-width: 100%;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto;
    z-index: 20;

    @include tablet() {
      min-width: 720px;
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .pop-page-content {
    max-width: 850px;
    margin: 1rem auto 0;
    background: $white;

    @include tablet() {
      margin: 5rem auto 0;
    }
  }
}

.pop-page--active {
}

.pop-page-outer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  @include tablet() {
    position: relative;
    overflow-x: inherit;
    overflow-y: inherit;
  }
}

.pop-page-content {
  position: relative;
  padding-top: 0;
  //padding-top: $navbar-height;
  //overflow: auto;
}

.pop-page-content {
  padding-bottom: 0;
  @include mobile() {
    padding-bottom: 150px;
  }
}

.pop-page--gallery .pop-page-content {
  padding-bottom: 0 !important;
}
